<template>
  <div class="tabs is-left">
    <ul>
      <li>
        <p class="title is-6 has-text-dark">
          <span class="icon is-marginless">
            <i :class="titleIcon"></i>
          </span>
          <span class="is-marginless">
            {{ title }}
          </span>
        </p>
      </li>
    </ul>
    <ul class="is-right" v-if="links">
      <li
        :class="{ 'is-active': $route.name === link.name }"
        v-for="(link, index) in links"
        :key="index"
      >
        <router-link :to="{ name: link.name }">{{ link.label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      type: String,
      required: false,
      default: () => 'fal fa-cogs'
    },
    links: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs > ul > li
  min-height: 2.5rem
</style>
